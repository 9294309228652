<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="never">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item>
              <el-input
                v-model="searchFrom.organizationName"
                :clearable="true"
                placeholder="地址"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchFrom.organizationCode" placeholder="请选择活动区域">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="add">添加</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <div class="table-block">
      <el-table :data="tableData" :header-cell-style="{'text-align': 'center', 'background': '#EEF1FC', 'color': '#999'}" :cell-style="{'text-align':'center'}" style="width: 100%">
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="date" label="日期"> </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="province" label="省份"> </el-table-column>
        <el-table-column prop="city" label="市区"> </el-table-column>
        <el-table-column prop="address" label="地址"> </el-table-column>
        <el-table-column prop="zip" label="邮编"> </el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="primary" size="small">编辑</el-button>
            <el-button type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      form: {
        name: '',
        value: '',
      },
      searchFrom: {
        organizationName: '',
        organizationCode: '',
      },
      options: [
        {
          label: 1,
          value: 1.1,
        },
        {
          label: 2,
          value: 2.2,
        },
      ],
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333,
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1517 弄',
          zip: 200333,
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1519 弄',
          zip: 200333,
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1516 弄',
          zip: 200333,
        },
      ],
    }
  },
  methods: {
    search() {
      console.log(123)
    },
    add() {
      console.log(123)
    },
  },
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
</style>
